<template>
  <div
    id="contents"
    class="status_board_tteok"
    :class="{
      production_status: tabIndex == 0,
      status_board_tteok_theme: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button
            v-if="tabIndex == 0"
            class="btn_admin"
            @click="FETCH_PRODUCTION_LIST"
          >
            새로고침 {{ timer }}
          </button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <ProductionStatusForm v-if="tabIndex == 0" />
      <MaterialStatusForm v-if="tabIndex == 1" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ProductionStatusForm from '@/views/forms/Custom/Monitor/22/ProductionStatusForm';
import MaterialStatusForm from '@/views/forms/Custom/Monitor/22/MaterialStatusForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    ProductionStatusForm,
    MaterialStatusForm,
  },
  data() {
    return {
      swiper: null,
      key: 0,
      timer: 0,
      asideIndex: [
        { title: '생산 현황', name: 'production_status' },
        { title: '원부자재 현황', name: 'material_status' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
    changeTimer(e) {
      this.timer = e;
    },
    // async FETCH_PRODUCTION_LIST() {
    //   await this.$store
    //     .dispatch('FETCH_PRODUCTION_LIST')
    //     .then(() => {
    //       this.key += 1;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.openOneButtonModal(
    //         '로드 중 오류',
    //         '공정 진행현황을 불러오는 중 오류 발생',
    //       );
    //     });
    // },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'tteok_monitoring';
</style>
