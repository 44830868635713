<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="input_text">
          <label>조회 설비기기</label>
          <div class="select_wrap">
            <my-local-selectric
              :id="'machine_selectric'"
              :watch="search_machine"
              :options="machine_options"
              @changeValue="changeSelectric($event)"
            ></my-local-selectric>
          </div>
        </div>
        <div>
          <span>조회기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ counterLogsProduct.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="5">생산 현황</th>
            </tr>
            <tr>
              <th>조회기간</th>
              <th>설비기기명</th>
              <th>제품명</th>
              <th>규격</th>
              <th>수량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in counterLogsProduct" :key="item.id">
              <td>
                {{ item.start_time | formatDateHHMMSS }} ~
                {{ item.end_time | formatDateHHMMSS }}
              </td>
              <td>{{ findInfoFromId(machines, item.plc_sensor_id).name }}</td>
              <td>{{ findInfoFromId(products, item.product_id).name }}</td>
              <td>{{ findInfoFromId(products, item.product_id).standard }}</td>
              <td>{{ $makeComma(item.value) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  components: { MyLocalSelectric },
  computed: {
    ...mapGetters({
      counter_logs_product: 'getCounterLogDayProduct',
      products: 'getProduct',
      unitCodes: 'getUnitCodes',
      plc_sensors: 'getPlcSensor',
      machines: 'getMachines',
      start_date: 'getStartDateFromCounterLog',
      end_date: 'getEndDateFromCounterLog',
      machine_options: 'getMachinesForSelectricAll',
      search_machine: 'getSearchMachineFromProductionStatus',
    }),
    counterLogsProduct() {
      if (this.counter_logs_product.length < 1) {
        return [];
      } else {
        return this.counter_logs_product
          .filter(
            x =>
              x.plc_sensor_id == this.search_machine ||
              this.search_machine == null,
          )
          .sort((a, b) => b.id - a.id);
      }
    },
  },
  methods: {
    changeSelectric(type) {
      this.$store.commit('setSearchMachineToProductionStatus', type);
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToCounterLog', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_COUNTER_LOG_DAY_PRODUCT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '생산 현황',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToCounterLog', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToCounterLog', e.target.value);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_COUNTER_LOG_DAY_PRODUCT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '생산 현황',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToCounterLog', yyyymmdd(new Date()));
      }
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '제품');
    }
    if (this.machine_options.length < 2) {
      await this.FETCH('FETCH_MACHINE', '설비기기');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.start_date == null) {
      const date = new Date();
      this.$store.commit('setEndDateToCounterLog', yyyymmdd(date));
      const date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToCounterLog', yyyymmdd(date2));

      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COUNTER_LOG_DAY_PRODUCT',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '생산 현황',
      ); //날짜
    }
  },
};
</script>
